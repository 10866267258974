import React from 'react';
import { Row, Col } from 'antd';
import Icon, { LayoutOutlined } from '@ant-design/icons';
import s from './index.module.less';
import { t } from 'i18next';

const ComingSoon = () => {
  return (
    <div>
      <Row className={s.comingSoonContainer} justify="center" align="middle">
        <Col>
          <Icon component={LayoutOutlined} className={s.icon} />
          <h3 className={'textCenterd'}>{t('ComingSoon')}</h3>
        </Col>
      </Row>
    </div>
  );
};

export default ComingSoon;
