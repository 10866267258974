import React from 'react';
// import { Link } from 'react-router-dom';
import { t } from 'i18next';
import get from 'lodash/get';

import { RoutePaths } from '../../routePaths';
import { Spin, List, Typography, Divider, Button } from '../../components';
import { CommonPagination, DateFormat } from '../../shared';
import Context from '../../context';
import { voidFunction, checkStandarisation } from '../../utils';
import { AlertServerError } from '../Errors';
import emptyContent from '../Empty/EmptyContent';
import { StandardizationDatasetIcon } from '../../components/StandardizationDatasetIcon';
import s from './index.module.less';
import CustomLink from '../Accessibility/Link';

const { Text, Paragraph } = Typography;

const DatasetList = (props) => {
  const {
    data = {},
    isLoading = true,
    column = 1,
    gutter = 0,
    divider = false,
    showInformation = false,
    highlight = true,
    showPagination = true,
    page = 1,
    paginationAlign = 'end',
    onShowSizeChange = voidFunction,
    limit = 10,
    topPagination = true,
  } = props;
  const { IsError = false, records = [], totalrecords = {} } = data;
  const totalRecords = get(totalrecords, 'records[0].RecordCount', 0);
  const [context] = React.useContext(Context);

  if (IsError) {
    return <AlertServerError />;
  }

  return (
    <Spin size="large" spinning={isLoading} tip={t('LoadingPleaseWait')}>
      <div className={s.datasetList}>
        {!IsError && (
          <>
            {topPagination && showPagination ? (
              <CommonPagination
                className={`d-flex mb-3 justify-content-${paginationAlign} paginationStyle`}
                total={totalRecords}
                hideOnSinglePage
                pageSize={limit}
                onChange={(page) => props.onPageChange(page)}
                responsive={true}
                current={page}
                onShowSizeChange={onShowSizeChange}
                limit={limit}
                showSizeChanger={false}
                showQuickJumper={true}
              />
            ) : null}

            {/* <List
              aria-label={t('V_Datasets')}
              tabIndex="-1"
              locale={emptyContent}
              dataSource={records}
              grid={{ gutter, column }}
              renderItem={(dataset) => (
                <span tabIndex="-1">
                  <CustomLink
                    to={`${RoutePaths.DATASET}/${dataset.source_code}`}
                    ariaLabel={dataset.SourceName}
                  >
                    <List.Item
                      role="listitem" aria-label={dataset?.SourceName}
                      className={`${context.screenContext === 'mobile' ? s.highlightMobile : ''} ${highlight ? s.highlight : ''
                        } ${s.dataItem} joyride-sector-step2 joyride-ministry-step2`}
                    >
                      <List.Item.Meta
                        className={s.displayIcon}
                        avatar={
                          <StandardizationDatasetIcon
                            iconSize={34}
                            isStandarized={checkStandarisation(dataset.Standardization)}
                            standarisationSubIcon={true}
                          />
                        }
                      />

                      <div>
                        <Paragraph
                          ellipsis={{ rows: 2, expandable: false }}
                          className="font-weight-semi-bold font-15 m-0"
                        >
                          {dataset.SourceName}
                        </Paragraph>
                        <Text type="secondary">
                          <span className={'font-14'}>{dataset.ministry_name}</span>
                        </Text>

                        {showInformation ? (
                          <Paragraph
                            className="font-12"
                            ellipsis={{
                              rows: 4,
                              expandable: true,
                              symbol: (
                                <div>
                                  <Button aria-label={t('PlusViewMore')} role="button" size="small" className="mt-3">
                                    {t('PlusViewMore')}
                                  </Button>
                                </div>
                              ),
                            }}
                          >
                            {dataset?.Justify?.trim() || dataset?.dataset_information}
                          </Paragraph>
                        ) : null}

                        <Text type="secondary">
                          <small>
                            {dataset.time_granularity || dataset.timeGranulity} &bull;{' '}
                            {dataset.location_level || dataset.LocalityGranularity} &bull; {`${t('Last_updated')}`}{' '}
                            <DateFormat date={new Date(dataset.LastUpdatedDate).toDateString()} /> &bull;{' '}
                            {`${dataset.noOfColumns} ${t('Columns')}`} &bull; {dataset.domain?.toUpperCase()}
                          </small>
                        </Text>
                      </div>
                    </List.Item>
                  </CustomLink>
                  <li>{divider ? <Divider /> : null}</li>
                </span>
              )}
            /> */}

            {records.map((dataset) => (
              <CustomLink to={`${RoutePaths.DATASET}/${dataset.source_code}`} ariaLabel={dataset.SourceName}>
                <div className="pr-3">
                  <List.Item
                    role="listitem"
                    aria-label={dataset?.SourceName}
                    className={`${context.screenContext === 'mobile' ? s.highlightMobile : ''} ${
                      highlight ? s.highlight : ''
                    } ${s.dataItem} joyride-sector-step2 joyride-ministry-step2`}
                  >
                    <List.Item.Meta
                      className={s.displayIcon}
                      avatar={
                        <StandardizationDatasetIcon
                          iconSize={34}
                          isStandarized={checkStandarisation(dataset.Standardization)}
                          standarisationSubIcon={true}
                        />
                      }
                    />

                    <div>
                      <Paragraph
                        ellipsis={{ rows: 2, expandable: false }}
                        className="font-weight-semi-bold font-15 m-0"
                      >
                        {dataset.SourceName}
                      </Paragraph>
                      <Text type="secondary">
                        <span className={'font-14'}>{dataset.ministry_name}</span>
                      </Text>

                      {showInformation ? (
                        <Paragraph
                          className="font-12"
                          ellipsis={{
                            rows: 4,
                            expandable: true,
                            symbol: (
                              <div>
                                <Button aria-label={t('PlusViewMore')} role="button" size="small" className="mt-3">
                                  {t('PlusViewMore')}
                                </Button>
                              </div>
                            ),
                          }}
                        >
                          {dataset?.Justify?.trim() || dataset?.dataset_information}
                        </Paragraph>
                      ) : null}

                      <Text type="secondary">
                        <small>
                          {dataset.time_granularity || dataset.timeGranulity} &bull;{' '}
                          {dataset.location_level || dataset.LocalityGranularity} &bull; {`${t('Last_updated')}`}{' '}
                          <DateFormat date={new Date(dataset.LastUpdatedDate).toDateString()} /> &bull;{' '}
                          {`${dataset.noOfColumns} ${t('Columns')}`} &bull; {dataset.domain?.toUpperCase()}
                        </small>
                      </Text>
                    </div>
                  </List.Item>
                </div>
              </CustomLink>
            ))}

            {showPagination ? (
              <CommonPagination
                className={`d-flex mt-2 justify-content-${paginationAlign} paginationStyle`}
                total={totalRecords}
                hideOnSinglePage
                pageSize={limit}
                onChange={(page) => props.onPageChange(page)}
                responsive={true}
                current={page}
                onShowSizeChange={onShowSizeChange}
                limit={limit}
                showSizeChanger={false}
                showQuickJumper={true}
              />
            ) : null}
          </>
        )}
      </div>
    </Spin>
  );
};

export default DatasetList;
